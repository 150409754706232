import React, {useState, useCallback } from 'react';
import MenuItem from './MenuItem';
import './header.scss';
import logo from '../../images/logo.png';
import translation from '../../services/translation'; 
import { useLocation } from "react-router-dom";

const Header = ({ menuNav }) => {
    const { pathname } = useLocation();
    const [menuState, setMenuState] = useState(pathname.substr(1) || "home");
    const [burgerActive, setBurgerActive] = useState(false);
    const [language, setLanguage] = useState('eng');

    const changeLanguage = useCallback((e) => {
        setLanguage(e);
        translation.setLanguage(e);
    },[setLanguage]);

    const onMove = useCallback((to) => {
        setMenuState(to);
        setBurgerActive(false);
    }, [setMenuState]);

    return (
        <header className="App-header">
            <div className="header-row">
                <div className="h-left-col">
                    <MenuItem className="logo" moveTo="home" to="/" onMove={onMove}>
                        <img src={logo} alt="" />
                    </MenuItem>
                    <div className="nav-container">
                        <MenuItem active={menuState === 'home'} to="/" moveTo="home" onMove={onMove}>{translation.home}</MenuItem>
                        <MenuItem active={menuState === "playOnline"} to="/" moveTo="playOnline" onMove={onMove}>Play Online</MenuItem>
                        <MenuItem active={menuState === "becomePartner"} to="/" moveTo="becomePartner" onMove={onMove}>Become a Partner</MenuItem>
                        <MenuItem active={menuState === "faq"} to="/faq" moveTo="faq" onMove={onMove}>FAQ</MenuItem>
                    </div>
                </div>
                <div className="h-right-col">
                    <div className="lang-sw active">
                        <p className="active-lang-v">{language}</p>
                        <div className="lang-drop-container">
                            <div className="lang-list-box">
                                <div className="drop-item-v" onClick={() => changeLanguage('eng')}>ENG</div>
                                <div className="drop-item-v" onClick={() => changeLanguage('ger')}>GER</div>
                                <div className="drop-item-v" onClick={() => changeLanguage('fra')}>FRA</div>
                            </div>
                        </div>
                    </div>
                    <MenuItem to="/contact-us" className="btn-v trans small" moveTo="contact" onMove={onMove}>Contact Us</MenuItem>
                </div>
            </div>
            {/*burger-nav start*/}
            <>
                <div className={`burger-navigation-container ${burgerActive ? 'active' : ''}`} onClick={() => setBurgerActive(!burgerActive)}>
                    <div className="burger-icon-box icon-burger15x"/>
                </div>
                <div className="m-nav-open-container">
                    <div className="nav-scroll-b-holder">
                        <div className="scroll-contain-v">
                            <div className="m-nav-content-b">
                                <div className="m-nav-link-b">
                                    <MenuItem active={menuState === 'home'} to="/" moveTo="home" onMove={onMove}><i className="icon-home-115x"/> {translation.home}</MenuItem>
                                    <MenuItem active={menuState === "contact"} to="/contact-us" moveTo="contact" onMove={onMove}><i className="icon-phone15x"/> Contact Us</MenuItem>
                                    <MenuItem active={menuState === "faq"} to="/faq" moveTo="faq" onMove={onMove}><i className="icon-15x"/> FAQ</MenuItem>
                                </div>
                                <div className="language-burger-v">
                                    <h3 className="lang-nav-title"><i className="icon-language-115x"/>Language</h3>
                                    <div className="l-mini-list-b">
                                        <button className={language === 'eng' ? 'active' : ''} name="eng" onClick={(e) => changeLanguage(e.target.name)}>English</button>
                                        <button className={language === 'ger' ? 'active' : ''} name="ger" onClick={(e) => changeLanguage(e.target.name)}>German</button>
                                        <button className={language === 'fra' ? 'active' : ''} name="fra" onClick={(e) => changeLanguage(e.target.name)}>French</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            {/*burger-nav END*/}
        </header>
  );
}

export default Header;
