import LocalizedStrings from 'react-localization';

let translations = new LocalizedStrings({
  eng: {
    home: "Home",
  },
  ger: {
    home: "Haus",
  },
  fra: {
    home: "Maison",
  }
});

export default translations;