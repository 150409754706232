import React , {useState, useEffect, useCallback} from 'react';
import InViewport from '../common/InViewport/InViewport';
import Feature from './Feature';

import './home.scss';
import icon1 from "../../images/game-icons/Combined Shape-2@1.5x.svg";
import icon2 from "../../images/game-icons/Combined Shape Copy@1.5x.svg";
import icon3 from "../../images/game-icons/Group 26@1.5x.svg";
import icon4 from "../../images/game-icons/Combined Shape Copy 2@1.5x.svg";
import icon5 from "../../images/game-icons/Football@1.5x.svg";
import icon6 from "../../images/game-icons/Ball@1.5x.svg";
import icon7 from "../../images/game-icons/Combined Shape-1@1.5x.svg";
import icon8 from "../../images/game-icons/HAnd@1.5x.svg";

import icon9 from "../../images/game-icons/Combined Shape Copy 3@1.5x.svg";
import icon10 from "../../images/game-icons/Combined Shape Copy 4@1.5x.svg";
import icon11 from "../../images/game-icons/Combined Shape Copy 6@1.5x.svg";
import icon12 from "../../images/game-icons/stats@1.5x.svg";
import icon13 from "../../images/game-icons/Combined Shape Copy 5@1.5x.svg";
import icon14 from "../../images/game-icons/animations@1.5x.svg";
import icon15 from "../../images/game-icons/Combined Shape@1.5x.svg";
import icon16 from "../../images/game-icons/plate@1.5x.svg";

const openPokerApp = (e) => {
    e.preventDefault();
    const width = 400;
    const height = 800;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    window.open('https://clubapp.clubdaddy.net', '', [
    'width=' + width,
    'height=' + height,
    'resizable=0',
    'scrollbars=1',
    'top=' + (screenHeight - height - 100) / 2,
    'left=' + (screenWidth - width) / 2
    ].toString());
}

const Home = () => {

    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        yourMessage: ''
    });

    const onHandleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };

    const sendFormToApi = useCallback(() => {
        console.log('send form info = ', formState );
    })

    useEffect(() => {
        console.log("olala = ", formState);
    },[formState]);


  return (
      <div className="homepage-container">
          <div className="section-b v-1">
              <div className="home-main-section">
                  <div className="left-t-col">
                      <div className="top-banner-text-b">
                          <InViewport addClassName="untitle-me">
                            <h1 className="title-text-v-1">
                                ClubDaddy- <br/> Free Online Games
                            </h1>
                          </InViewport>
                          <p className="b-ap-form-text">
                              Battle your friends from all around the world in the best <br/> club app with ClubDaddy’s
                              free online games.
                          </p>
                      </div>
                      <div className="download-app-section">
                          <h2 className="mini-b-title">Coming Soon On</h2>
                          <div className="app-button-row">
                              <div className="app-button-v">
                                  <div className="app-icon-v">
                                      <i className="icon-apple15x"/>
                                  </div>
                                  <p className="app-name-v">App Store</p>
                              </div>
                              <div className="app-button-v">
                                  <div className="app-icon-v">
                                      <i className="icon-android15x"/>
                                  </div>
                                  <p className="app-name-v">Google Play</p>
                              </div>
                          </div>
                          <div className="private-club-b">
                              <h2 className="play-b-title-t">ClubDaddy Private Clubs are available now!</h2>
                              <a href="https://clubapp.clubdaddy.net" target="_blank" className="btn-v bg-color big" onClick={openPokerApp}>Play Online</a>
                          </div>
                      </div>
                  </div>
                  <div className="right-img-col">
                      <div className="img-t-b" id="home"/>
                  </div>
              </div>
          </div>
          <div className="section-b v-2">
              <div className="b-center">
                  <div className="chip-bg-v">
                      <p className="absolute-b-text">Claim your daily free chips and try our skills on our free slots,
                          sportsbook and poker tables. Test your skills to bet on our free sports matches and run with
                          the legends: Barcelona, AC Milan, Liverpool, Los Angeles Lakers, bet on your favorite team to
                          win it all and finish on top of our leaderboard. Our free games are there for you to enjoy,
                          join the ClubDaddy party and win huge prizes.</p>
                  </div>
              </div>
          </div>
          <div className="section-b v-5" id="playOnline">
              <div className="main-divider-row">
                  <div className="solutions-left-col bigger">
                      <div className="title-box bg-line">
                          <h3 className="title-text-v-3">Score Big in Our League Games</h3>
                      </div>
                      <p className="p-solutions-text">ClubDaddy’s league games offer classic poker gameplay, sizzling sports action and a large variety of Vegas type casino games to trigger your competitive game spirit better than any regular online poker app. Move up the ranks to reach the top of the ladder and win millions of chips in our Bet Your Hand proprietary Jackpot. </p>
                  </div>
                  <div className="img-bg-container-v">
                      <div className="img-bd-league-games"/>
                  </div>
              </div>
              <div className="mini-bg-wrapper">
                  <div className="main-divider-row">
                      <div className="solutions-left-col">
                          <div className="games-icons-wrapper">
                              <div className="g-col-1">
                                  <h4 className="title-text-v-4 g-list-t">Games selection</h4>
                                  <div className="group-icons-b">
                                    <Feature color="1" icon={icon1} text="No Limit Holdem" />
                                    <Feature color="2" icon={icon2} text="Pot Limit Holdem" />
                                    <Feature color="3" icon={icon3} text="Pineapple" />
                                    <Feature color="4" icon={icon4} text="Vegas style Casino games" />
                                    <Feature color="7" icon={icon5} text="Sports pre-match betting" />
                                    <Feature color="5" icon={icon6} text="Sports live betting" />
                                  </div>
                                  <h5 className="coming-soon-t">Coming Soon</h5>
                                  <div className="group-icons-b">
                                    <Feature color="6" icon={icon7} text="Backgammon" />
                                    <Feature color="6" icon={icon8} text="Rock Paper Scissors" />
                                  </div>
                              </div>
                              <div className="g-col-2">
                                  <h4 className="title-text-v-4 g-list-t">Exciting features</h4>
                                  <div className="group-icons-b">
                                    <Feature color="7" icon={icon9} text="Bet your Hand Jackpot" />
                                    <Feature color="8" icon={icon10} text="Insurance" />
                                    <Feature color="1" icon={icon11} text="Splashy choices for table design" />
                                    <Feature color="3" icon={icon12} text="Player statistics" />
                                    <Feature color="5" icon={icon13} text="Hand strength information" />
                                    <Feature color="6" icon={icon14} text="Amazing animations" />
                                  </div>
                                  <h5 className="coming-soon-t">Coming Soon</h5>
                                  <div className="group-icons-b">
                                    <Feature color="6" icon={icon15} text="Backgammon" />
                                    <Feature color="6" icon={icon16} text="Rock Paper Scissors" />
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="img-bg-container-v"/>
                  </div>
              </div>
          </div>
          {/*OR start*/}
          <div className="divider-line">
              <h3 className="line-title">
                  <span>Or</span>
              </h3>
          </div>
          {/*OR END*/}
          <div className="section-b v-6">

              <div className="main-divider-row left-img">
                  <div className="img-bg-container-v">
                      <div className="img-bd-private-club"/>
                  </div>
                  <div className="solutions-left-col bigger">
                      <div className="title-box bg-line">
                          <h3 className="title-text-v-3">ClubDaddy Private Clubs</h3>
                      </div>
                      <p className="p-solutions-text">Create your own private club so your friends can join you on the
                          tables and compete in your free club games. Poker, Casino games and tantalising sportsbook
                          with a diverse selection of games with a myriad of options that allow your friends to enjoy
                          themselves at the tables.</p>
                  </div>
              </div>
              <div className="mini-bg-wrapper right-radius">
                  <div className="b-title-container">
                      <h2 className="title-text-v-5">Ready to get started?</h2>
                  </div>
                  <div className="button-container">
                      <a href="https://clubapp.clubdaddy.net/" target="_blank" onClick={openPokerApp} className="btn-v bg-color big">Play Now</a>
                  </div>
              </div>
          </div>
          <div className="section-b v-7">
              <div className="mini-bg-wrapper">
                  <div className="main-divider-row">
                      <div className="solutions-left-col">
                          <div className="title-box bg-line">
                              <InViewport addClassName="unbrand-me">
                                <h3 className="title-text-v-3">ClubDaddy Branded Partner Solutions</h3>
                              </InViewport>
                          </div>
                          <p className="p-solutions-text">ClubDaddy Club games are perfectly suited to add value to your
                              product offer and create new and exciting marketing opportunities. Use our ClubDaddy
                              webapp as a branded HTML5 club solution from inside your own website. Create unique club
                              codes so only one button click separates your clients from competing within their own
                              circle of friends. If you are interested you can check the ClubDaddy webapp demo by
                              pressing on the button “Try Demo”.</p>
                          <a href="https://clubapp.clubdaddy.net/" target="_blank" className="btn-v bg-color big" id="becomePartner" onClick={openPokerApp}>Try Demo</a>
                      </div>
                      <div className="img-bg-container-v">
                          <div className="img-bd-box"/>
                      </div>
                  </div>
                  <div className="become-a-partner-container">
                      <div className="b-ap-bg-container">
                          <div className="b-ap-content-box">
                              <div className="t-container-v">
                                  <h3 className="title-text-v-2">Become a partner</h3>
                              </div>
                              <div className="grid-v">
                                  <div className="col-1">
                                      <p className="b-ap-form-text">If you want to become our partner and use the
                                          ClubDaddy webapp club solution inside you website, please feel your details
                                          here and send us a message. Our business specialist will contact you for
                                          further discussions and will answer all your questions.
                                          <br/><br/>
                                          Also if you want to check our webapp demo you can check it above by pressing
                                          on the “Try Demo” button.</p>
                                  </div>
                                  <div className="col-2">
                                      {/* TODO: Goes to a separate component */}
                                      <div className="become-form-box">

                                          <div className="bcm-f-row col-2">
                                              <div className="f-item-box">
                                                  <label className="f-item-title">First Name</label>
                                                  <input className="input-v big full-w" type="text" name="firstName" onChange={onHandleChange}/>
                                              </div>
                                              <div className="f-item-box">
                                                  <label className="f-item-title">Last Name</label>
                                                  <input className="input-v big full-w" type="text" name="lastName"  onChange={onHandleChange}/>
                                              </div>
                                          </div>

                                          <div className="bcm-f-row">
                                              <div className="f-item-box">
                                                  <label className="f-item-title">Email</label>
                                                  <input className="input-v big full-w" type="Email" name="email" onChange={onHandleChange}/>
                                              </div>
                                          </div>

                                          <div className="bcm-f-row">
                                              <div className="f-item-box">
                                                <label className="f-item-title">Your Message</label>
                                                <textarea className="textarea-f-v" name="yourMessage" onChange={onHandleChange}/>
                                              </div>
                                          </div>
                                          <div className="button-container">
                                            <button className="btn-v bg-color big" onClick={sendFormToApi}>Send</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="section-b v-8">
              <div className="disclaimer-container">
                  <div className="disclaimer-content">
                      <h4 className="disclaimer-title-v">Score Big in Our League Games</h4>
                      <p className="disclaimer-text-v">
                          ClubDaddy’s league games offer classic poker gameplay, sizzling sports action and a large
                          variety of Vegas type casino games to trigger your competitive game spirit better than any
                          regular online poker app. Move up the ranks to reach the top of the ladder and win millions of
                          chips in our Bet Your Hand proprietary Jackpot.
                      </p>
                  </div>
              </div>
          </div>
      </div>
  );
}
export default Home;
