import React, { useState, useEffect, useCallback } from 'react';
import './privacy.scss';
import policyData from '../../assets/json/privacyAndPolicy.json';

const Privacy = () => {
    return(
        <div className="privacy-container">
            <div className="privacy-content-holder">
                <div className="privacy-header">
                    <h1 className="title-text">Cookies Policy</h1>
                    <p className="last-updated">Last updated: June 11, 2020</p>
                    <p>This Cookies Policy explains what Cookies are and how We use them. You should read this policy 
                        so You can understand what type of cookies We use, or the information We collect using Cookies 
                        and how that information is used.
                    </p>
                    <p>Cookies do not typically contain any information that personally identifies a user, but personal
                        information that we store about You may be linked to the information stored in and obtained from 
                        Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.
                    </p>
                    <p>We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.</p>
                </div>
                <div className="privacy-content">
                    <h2 className="section-header">Interpretations and Definitions</h2>
                    {policyData.definitions.map((item) => (
                        <div key={item}>
                            <h2 className="section-title-text">{item.section}</h2>
                            <p className="section-text">{item.text}</p>
                            <ul>
                                {!!item.subSections && item.subSections.map((listItem, index) => (
                                    <li key={index}>
                                        {listItem.title} {listItem.text} {!!listItem.link && <a href={listItem.link}>{listItem.link}</a>}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                <h2 className="section-header">The use of the Cookies</h2>
                    {policyData.useOfCookies.map((item, index) => (
                        <div key={index}>
                            <h2 className="section-title-text">{item.section}</h2>
                            <p className="section-text">{item.text}</p>
                            <ul>
                                {!!item.subSections && item.subSections.map((listItem, index) => (
                                    <li key={index}>
                                        <span className="subsection-title">{listItem.title}</span> {listItem.text} 
                                        {!!listItem.link && <a target="_blank" href={listItem.link}>{listItem.link}</a>}
                                    </li>
                                ))}
                            </ul>
                            {!!item.cookyTypes && item.cookyTypes.map((type, index) => (
                                <div className="cookies" key={index}>
                                    <h2>{type.title}</h2>
                                    <p>{type.type}</p>
                                    <p>{type.administratedBy}</p>
                                    <p>{type.purpose}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Privacy;
