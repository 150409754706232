import React, { useState, useCallback } from 'react';

const FaqItem = ({data, select, active}) => {
    console.log("faq data = ", data);

    return (
        <div className={`spoiler-item-container ${active ? "active" : ''}`} onClick={select}>
            <div className="open-sp-b icon-plus1x" />
            <h4 className="single-spl-title">{data.title}</h4>
            <div className="sp-opened-holder">
                <p className="sp-r-text-v">
                    {data.text}
                </p>
            </div>
        </div>
    )
};

export default FaqItem;