import React ,{useState}from 'react';
import { BrowserRouter as Router , Route, Switch} from "react-router-dom";
import Header from "../header/header";
import Footer from "../footer/footer";
import Home from "../home/home";
import Faq from "../faq/faq";
import './App.scss';
import Contacts from "../contacts/contacts";
import TermsAndConditions from '../t&c/t&c';
import Privacy from '../privacy/privacy';

function App() {
  return (
      <div className="App">
        <Router>
          <Header />
          <div className="main-content">
            <Switch>
              <Route path="/faq" component={Faq} />
              <Route path="/contact-us" component={Contacts} />
              <Route path="/terms" component={TermsAndConditions} />
              <Route path="/privacy-policy" component={Privacy} />
              <Route path="/" component={Home} />
            </Switch>
          </div>
          <Footer/>
        </Router>
      </div>
  );
}
export default App;
