import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

const MenuItem = ({ to, moveTo, className, active, children, onMove }) => {
    const onClick = useCallback(() => {
        const node = document.getElementById(moveTo);
        if (node) {
            node.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        if (onMove) onMove(moveTo);
    }, [moveTo, onMove]);

    let classes = className || 'nav-link';
    if (active) classes += ' active';

    return (
        <Link to={to}  className={classes} onClick={onClick}>
            {children}
        </Link>
    );
}

export default MenuItem;